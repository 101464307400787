<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Connecting
        </h2>
        <b-spinner
          variant="primary"
          type="grow"
          label="Spinning"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  created() {
    const tokenAuth = this.$route.query.token
    if (!tokenAuth) {
      this.$router.push({ name: '/' })
    }
    this.auth(tokenAuth)
  },

  methods: {
    auth(tokenAuth) {
      useJwt.auth({
        token: tokenAuth,
      })
        .then(response => {
          const { userData } = response.data
          useJwt.setToken(response.data.accessToken)
          useJwt.setRefreshToken(response.data.refreshToken)
          localStorage.setItem('userData', JSON.stringify(userData))
          this.$ability.update(userData.ability)
          if (response.data.accessToken) {
            if (userData.changes.new_email) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'CompassIcon',
                  variant: 'danger',
                  text: `Your email has been changed from ${userData.changes.old_email} to ${userData.changes.new_email}`,
                },
              })
            } else if (userData.changes.new_phone || userData.changes.new_phone_country_code) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'CompassIcon',
                  variant: 'danger',
                  text: `Your phone has been changed from ${userData.changes.old_phone_country}|${userData.changes.old_phone} to ${userData.changes.new_phone_country_code}|${userData.changes.new_phone}`,
                },
              })
            } else {
              this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Welcome',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'You have successfully logged in as. Now you can start to explore!',
                    },
                  })
                })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'CompassIcon',
                variant: 'danger',
                text: 'Your email or password is incorrect!',
              },
            })
          }
        })
        .catch(error => {
          // console.log(JSON.stringify(error))
          if (error.response) {
            if (error.response.status === 409) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'CompassIcon',
                  variant: 'danger',
                  text: 'Invalid user',
                },
              })
              this.$router.push('/')
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'CompassIcon',
                variant: 'success',
                text: 'Success',
              },
            })
            this.$router.push('/')
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/pages/page-misc.scss';
</style>
